/*
 * File: otm
 * Desc: Creates OTM iframes
 * Author: Ferry Kobus - ferry@dtcmedia.nl
 *
 * Will dynamically create an OTM if you place a div in a page:
 * <div class="preselect-otm" data-dtcmedia-otm data-otm-key="OTM_SLEUTEL" data-otm-kenmerk="OTM_KENMERK">
 *    <noscript><iframe id="taxatie_iframe" src="http://taxatiemodule.autodna.nl/campagne/OTM_SLEUTEL/" width="960" height="552" scrolling="0" frameborder="0"></iframe></noscript>
 * </div>
 * In de url worden de volgende parameters overgenomen voor de iframe: 'kenteken', 'kenmerk', 'merk', 'model','uitvoering'
 *
 */

;(function(window) {
	'use strict';

	var possibleParams = [ 'kenmerk', 'kenteken', 'merk', 'model','uitvoering' ],
		acceptedTLDs = ['nl', 'org', 'com', 'net', 'frl'],
		defaults = {
			host: 'onlinetaxatiemodule.nl',
			forceHost: true,
			classes: 'taxatie_iframe_container'
		},
		// Custom interval for IE11, because otherwise IE11 crashes when using iframeresizer
		customInterval = (!(window.ActiveXObject) && "ActiveXObject" in window) ? -32 : 32;


	function loadScript(url, callback) {
		// Adding the script tag to the head as suggested before
		var head = document.getElementsByTagName('head')[0],
			script = document.createElement('script');

		script.type = 'text/javascript';
		script.src = url;
		// Then bind the event to the callback function.
		// There are several events for cross browser compatibility.
		script.onreadystatechange = callback;
		script.onload = callback;
		// Fire the loading
		head.appendChild(script);
	}

	function setupOTM(otm, options) {
		var options = options || {},
			_otm = otm,
			otm_key = _otm.getAttribute('data-otm-key'),
			selectors = _otm.getElementsByTagName("SELECT"),
			settings = {},
			iframe = document.createElement('iframe'),
			iframe_container = document.createElement('div'),
			queryString = '';

		// Returns the current host if the TLD is in acceptedTLDs and the options.host isn't set
		function getHost() {
			if (defaults.forceHost === true) {
				return 'https://www.' + defaults.host;
			}
			var host = (window.location.protocol === 'https:') ? 'https://' : 'http://'
			if (settings.host != defaults.host) {
				var parts = settings.host.split('.')
				if (acceptedTLDs.indexOf(parts[parts.length - 1]) > -1) {
					if (parts[0] === 'taxatiemodule') {
						return parts.join('.');
					} else {
						return host + 'taxatiemodule.' + parts.join('.');
					}
				}
			}
			return host + defaults.host;
		}

		// get the value of a key from the query string
		function getURLParameter(name) {
			return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20')) || ""
		}

		// build the new query string
		function buildQueryString(params) {
			return Object.keys(params).map(function(k){
				return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
			}).join('&');
		}

		// creates the actual otm
		function createOTM(kenmerk) {
			if (kenmerk != undefined && kenmerk != "") {
				var index = possibleParams.indexOf('kenmerk');
				if (index > -1) {
					possibleParams.splice(index, 1);
				}
				queryString = "kenmerk=" + kenmerk;
			}
			var params = possibleParams.reduce(function(result, key) {var val=getURLParameter(key);if(val!=''){result[key]=val;}return result;},{}),
				url = getHost() + "/campagne/" + otm_key + "/?" + queryString + buildQueryString(params);

			iframe.src=url;
			iframe.setAttribute('data-iframe-src', url);
			iframe_container.appendChild(iframe);
			_otm.parentNode.insertBefore(iframe_container, _otm.nextSibling);

			// trigger function after iframe is loaded
			iframe.onreadystatechange = readyOTM;
			iframe.onload = readyOTM;
		}

		function readyOTM() {
			iFrameResize({
				interval: customInterval,
				resizedCallback: function(data) {
					iframe_container.style.height='auto';

					// cleanup after first init
					if (inDoc(_otm)) {
						if (typeof _otm.ready == 'function') {
							_otm.ready();
						}
						_otm.parentNode.removeChild(_otm);
					}
				}
			}, iframe);
			//Tell GTM that iframe is done. Should trigger GTM script to fix cross-domain Analytics cookies.
			if (typeof dataLayerDTC !== 'undefined') {
				dataLayerDTC.push({'event': 'iframe_loaded'});
			}
		}

		/**
		 * Check if a node is in the document.
		 * Note: document.documentElement.contains should work here
		 * but always returns false for comment nodes in phantomjs,
		 * making unit tests difficult. This is fixed by doing the
		 * contains() check on the node's parentNode instead of
		 * the node itself.
		 *
		 * @param {Node} node
		 * @return {Boolean}
		 */

		function inDoc(node) {
			if (!node) {
				return false;
			}
			var doc = node.ownerDocument.documentElement,
				parent = node.parentNode;
			return doc === node ||
				doc === parent ||
				!!(parent && parent.nodeType === 1 && (doc.contains(parent)));
		}

		/**
		 * Initialize the OTM
		 * First checks if there are selector (layover) and do some crossbrowser event binding
		 *
		 * If not, just go for the data-otm-kenmerk value
		 */
		function initOTM() {
			if (selectors.length > 0) {
				var select = selectors[0];
				if (select.addEventListener) {
					select.addEventListener('change', function() {
						createOTM(this.value)
					}, false);
				} else if (select.attachEvent) {
					select.attachEvent('onchange', function() {
						createOTM(this.value)
					});
				}
			} else {
				createOTM(_otm.getAttribute('data-otm-kenmerk'));
			}
		}

		// fix default options with provided options
		for (var option in defaults) {
			if (defaults.hasOwnProperty(option)) {
				settings[option] = options.hasOwnProperty(option) ? options[option] : defaults[option];
			}
		}

		// some defaults that need to be applied to the iframe_container and iframe itself of the otm
		//iframe_container.setAttribute('id', 'taxatie_iframe_container');
		iframe_container.classList.add(settings.classes);
		iframe_container.style.width="100%";
		iframe_container.style.height="0px";

		iframe.width="100%";
		iframe.frameBorder=0;
		iframe.scrolling="no";
		// removes unwanted spacing
		iframe.style.display='block';

		// check if iFrameResize is available
		if (typeof(iFrameResize) !== 'function') {
			loadScript('https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.5/iframeResizer.min.js', function() {
				initOTM();
			});
		} else {
			initOTM();
		}
	}


	function factory() {
		function init(element, options) {
			function chkType() {
				if(!element.tagName) {
					throw new TypeError('Object is not a valid DOM element');
				}
			}
			if (element) {
				chkType();
				setupOTM(element, options);
				OTMs.push(element);
			}
		}

		var OTMs;

		return function createOTMF(target, options) {
			OTMs = [];
			switch (typeof(target)) {
				case 'undefined':
				case 'string':
					var elements = document.querySelectorAll( target || '[data-dtcmedia-otm]' )
					Object.keys(elements).forEach(function(index) {
						init(elements[index], options)
					})
					break;
				case 'object':
					init(target, options);
					break;
				default:
					throw new TypeError('Unexpected data type ('+typeof(target)+')');
			}
			return OTMs;
		};
	}

	function createJQueryPublicMethod($){
		if (!$.fn) {
			console.log('Unable to bind to jQuery, it is not fully loaded.');
		} else {
			$.fn.createOTM = function $createOTMF(options) {
				function init(index, element) {
					setupOTM(element, options);
				}
				return this.filter('[data-dtcmedia-otm]').each(init).end();
			};
		}
	}

	if (window.jQuery) {
		createJQueryPublicMethod(jQuery);
	}

	if (typeof define === 'function' && define.amd) {
		define([], factory);
	} else if (typeof module === 'object' && typeof module.exports === 'object') {
		module.exports = factory();
	} else {
		window.createOTM = window.createOTM || factory();
	}
})(window || {});
